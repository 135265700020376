
history.pushState(null, null, location.href);
window.onpopstate = function(event) {
    history.go(1);
};
/**
Core script to handle the entire theme and core functions
**/
// makes sure the whole site is loaded
jQuery(window).load(function() {
    // will first fade out the loading animation
    jQuery("#status").fadeOut();
    // will fade out the whole DIV that covers the website.
    jQuery("#preloader").delay(70).fadeOut("slow");
});


$(function(){
    $('.scroller').slimScroll({
        height: '100px'
    });
      $('#specialitatiScroller').slimScroll({
        height: '400px'
    });
    $('#skillsScroller').slimScroll({
        height: '400px'
    });
        $('#attributevaluescroller').slimScroll({
        height: '400px'
    });
});

$(function () {
  $('[data-toggle="popover"]').popover()
});


$(document).ready(function () {
  var trigger = $('.hamburger'),
      overlay = $('.overlay'),
     isClosed = false;

    trigger.click(function () {
      hamburger_cross();
    });

    function hamburger_cross() {

      if (isClosed == true) {
       // overlay.hide();
       $('#logo').show();
        trigger.removeClass('is-open');
        trigger.addClass('is-closed');
        isClosed = false;
      } else {
      //  overlay.show();
      $('#logo').hide();
        trigger.removeClass('is-closed');
        trigger.addClass('is-open');
        isClosed = true;
      }
  }

  $('[data-toggle="offcanvas"]').click(function () {
        $('#wrapper').toggleClass('toggled');
  });

  $('#wrapper').toggleClass('toggled');


});

//incarcam scriptul cu ascunderea coloanelor in format tablet sau phone
$(function () {
    $('.footable').footable({
        breakpoints: {
            tiny: 100,
            medium: 555,
            big: 2048
        }
    });
});

$.fn.modal.Constructor.prototype.enforceFocus = function () {
    var that = this;
    $(document).on('focusin.modal', function (e) {
        if ($(e.target).hasClass('select2-input')) {
            return true;
        }

        if (that.$element[0] !== e.target && !that.$element.has(e.target).length) {
            that.$element.focus();
        }
    });
}



function ScrollToTop(object) {
    // $('html,body').animate({ opacity: 0.95,scrollTop: $(object).offset().top - 260 }, 500, 'linear');
    //$('html,body').animate({ opacity: 1 });
    $('html,body').animate({scrollTop: $(object).offset().top - 260 }, 500, 'linear');

}

function totTop(){
    $('html, body').animate({ scrollTop: 0 }, 'slow', 'linear');
}


jQuery(document).ready(function() {

    $('.wrapper2').popover({
        selector: '.my-tip2',
        trigger: 'hover'
    });
    $("[data-toggle=popover]").popover();
    $('#edit').popover({ html : true});

    $('[data-toggle="tooltip"]').tooltip();

    $("[data-toggle=popover]").on('shown.bs.popover', function () {
    })

    var el;
    var url = location.href.toLowerCase();

    var menu = $('#sidebar-wrapper');


    menu.find("li > a").each(function() {
        var path = $(this).attr("href").toLowerCase();

        if (path.length > 1 && url.indexOf(path) > -1) {
            $(this).parent().addClass('active');
        }
    });

    toastr.options.closeMethod = 'fadeOut';
    toastr.options.closeDuration = 800;
    toastr.options.closeEasing = 'swing';


  $('.date').datepicker({
        weekStart: 1,
        multidateSeparator: ".",
        autoclose: true,
        format: "dd.mm.yyyy",
        todayBtn: true,
        zoom:6,
        scrollwheel:true,
        language: "ro-ro",
        todayHighlight: true
    });

   $('.select').selectpicker({
                size: 4,
            });

  $('#summernote').summernote({
    minHeight:100,
    toolbar: [
    ['style', ['style']],
       ['font', ['bold', 'italic', 'underline', 'clear']],
       ['fontname', ['fontname']],
       ['color', ['color']],
       ['para', ['ul', 'ol', 'paragraph']],
       ['height', ['height']],
       ['insert', ['link', 'picture', 'hr']],
       ['view', ['fullscreen', 'codeview']],
    ],
  });


});
